<template>
  <div class="block">
    <video
      src="../assets/videos/mouth.mp4"
      autoplay
      loop
      muted
      defaultMuted
      playsinline
    ></video>
  </div>

  <div class="logo-overlay p-5">
    <div>
      <svg
        viewBox="0 0 260 120"
        width="260"
        xmlns="http://www.w3.org/2000/svg"
        fill="white"
      >
        <use href="../assets/svg/vap-logo.svg#logo" />
      </svg>
    </div>
  </div>
  <div class="nav-overlay p-5">
    <NuxtLink to="/podcast"> <h1 class="title">Podcast</h1></NuxtLink>

    <NuxtLink to="/hoerbuch"
      ><h1 class="title py-3">Hörbuch & Hörspiel</h1></NuxtLink
    >
    <NuxtLink to="/story"><h1 class="title">Unsere Story</h1></NuxtLink>
  </div>

  <!-- <div class="lang-overlay p-5">
    <NuxtLink to="/podcast">
      <div class="language">
        <span class="fw-bold">EN</span> <span>/</span> <span>DE</span>
      </div></NuxtLink
    >
  </div> -->
</template>

<style scoped>
.title {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
}

@media (max-width: 575.98px) {
  .title {
    font-size: 2.7rem;
  }
}

.title-disabled {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
}

.language {
  font-size: 2rem;
  font-weight: 300;
  color: white;
}

.title:hover {
  cursor: pointer;
  color: #fc4667;
}

.logo-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.nav-overlay {
  position: absolute;
  bottom: 0;
}

.lang-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
}

.block {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
